<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.2305 3.16998L12.2305 20.17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M8.70001 5.51477L12.1997 1.99996L15.7 5.51477" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M8.70001 16.9999L12.1997 20.5099L15.7 16.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M20.2086 11.1468L3.20862 11.1468" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M6.78967 7.70001L3.27967 11.1997L6.78967 14.7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M17 7.70001L20.51 11.1997L17 14.7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
  </svg>
</template>
